.actbanner {
  position: relative;
  top: 0;
}

.actbannerimg  {
  position: absolute;
  top: 0;
}

.actbanner > img:nth-child(2) {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.actbanner > img:nth-child(1) {
  width: 800px;
  z-index: 1;
  right: 0;
}

.actbanner > img:nth-child(3) {
  right: 10%;
  top: 125px;
  z-index: 2;
}

.actbannertxt, .actbannertxt > h1 {
  top: 125px;
  left: 10%;
  color: white;
  font-weight: bold;
}

.actpricing {
  position: relative;
  top: 350px;
}

.acthead {
  color: #00325A;
  font-weight: bold;
  text-align: center;
}

.acthead > span {
  color: #f0a773;
}

.acthorizontal {
  display: flex;
  justify-content: center;
}

.acthorizontal > div {
  width: 100px;
  height: 1px;
  background-color: whitesmoke;
  border-radius: 2px;
  margin-bottom: 15px;
}

.pricing {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  margin: 50px auto 3em;
}

.pricing-item {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: stretch;
  align-items: stretch;
  text-align: center;
  -webkit-flex: 0 1 330px;
  flex: 0 1 330px;
  padding-bottom: 30px;
}

.pricing-item > h4 {
  padding: 15px;
  color: white;
}

.pricing-item p {
  color: white;
}

.pricing__item--featured p {
  font-size: 20px;
}

.pricing-item > div {
  margin-top: 20px;
}

.pricing-action {
  color: inherit;
  border: none;
  background: none;
}

.pricing-action:focus {
  outline: none;
}

.pricing-feature-list {
  text-align: left;
}

.pricing-palden .pricing-item {
  font-family: 'Open Sans', sans-serif;
  cursor: default;
  color: #84697c;
  background: #fff;
  box-shadow: 0 0 10px rgba(46, 59, 125, 0.23);
  border-radius: 5px 5px 5px 5px;
  margin: 1em;
}

@media screen and (min-width: 66.25em) {
  .pricing-palden .pricing-item {
      margin: 1em -0.5em;
  }
  .pricing-palden .pricing__item--featured {
      margin: 0;
      z-index: 10;
      box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
  }
}

.pricing-palden .pricing-deco {
  border-radius: 10px 10px 0 0;
  background: rgba(76, 70, 101, 0.99);
  padding: 4em 0 9em;
  position: relative;
}

.pricing-palden .pricing-deco-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 160px;
}

.pricing-palden .pricing-title {
  font-size: 0.75em;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #fff;
}

.pricing-palden .deco-layer {
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}

.pricing-palden .pricing-item:hover .deco-layer--1 {
  -webkit-transform: translate3d(15px, 0, 0);
  transform: translate3d(15px, 0, 0);
}

.pricing-palden .pricing-item:hover .deco-layer--2 {
  -webkit-transform: translate3d(-15px, 0, 0);
  transform: translate3d(-15px, 0, 0);
}

.pricing-palden .icon {
  font-size: 2.5em;
}

.pricing-palden .pricing-price {
  font-size: 5em;
  font-weight: bold;
  padding: 0;
  color: #fff;
  margin: 0 0 0.25em 0;
  line-height: 0.75;
}

.pricing-palden .pricing-currency {
  font-size: 0.15em;
  vertical-align: top;
}

.pricing-palden .pricing-period {
  font-size: 0.15em;
  padding: 0 0 0 0.5em;
  font-style: italic;
}

.pricing-palden .pricing__sentence {
  font-weight: bold;
  margin: 0 0 1em 0;
  padding: 0 0 0.5em;
}

.pricing-palden .pricing-feature-list {
  margin: 0;
  padding: 0.25em 0 2.5em;
  list-style: none;
  text-align: center;
}

.pricing-palden .pricing-feature {
  padding: 1em 0;
}

.pricing-palden .pricing-action {
  font-weight: bold;
  margin: auto 3em 2em 3em;
  padding: 1em 2em;
  color: #fff;
  border-radius: 30px;
  background: #4d4766;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.pricing-palden .pricing-action:hover,
.pricing-palden .pricing-action:focus {
  background-color: #100A13;
}

.pricing-palden .pricing-item--featured .pricing-deco {
  padding: 5em 0 8.885em 0;
}

.actfeatures {
  background-repeat: no-repeat;
  text-align: center;
}

.actfeatures h4 {
  color: #00325A;
  font-weight: bold;
}

.acttable {
  margin-top: 50px;
}

.acttable .col-12 {
  padding: 10px;
}

.acttable p {
  color: #00325A;
  font-weight: bold;
}

.acttable h5 {
  color: #E8721E;
  font-size: 20px;
  font-weight: bold;
}

.grey > div {
  background-color: grey;
  width: 50px;
}

.actqa {
  margin-top: 50px;
}

.actqa h3 {
  color: #4076A1;
  font-weight: bold;
}

.actqadiv {
  margin-top: 40px;
}

.actqadiv h5 {
  color: #E8721E;
  font-weight: bold;
  font-size: 18px;
}

.actqadiv p {
  color: #9A9A9A;
  font-size: 16px;
}

.tidesc {
  display: flex;
}

.tileftbar {
  width: 10em;
  background-image: linear-gradient(#00325A, #4076A1);
  margin-right: 15px;
}

.tidesc div:nth-child(2) {
  color: #656565;
}

.tiheader {
  margin-top: 50px;
  background-image: linear-gradient(to right, #FF9E59, #C95400);
}

.tiheader > h1 {
  margin-right: 1em;
  color: white;
  font-weight: bold;
  font-size: 35px;
  padding: 10px 0;
}

.tipairoot {
  border: 1px solid grey;
  padding: 0;
  text-align: center;
  margin-top: 30px;
}

.tipairoot > div {
  margin-top: 5px;
}

.tipairoot h3 {
  color: #E8721E;
  font-weight: bold;
}

.tipairDiv {
  background-image: linear-gradient(to right, #4076A1,#00325A );
  width: 100%;
  padding: 20px;
  border-bottom: 10px solid #FF9E59;
  display: flex;
  justify-content: space-around;
}

.tipairDiv .row {
  margin-bottom: 20px;
}

.tipair {
  background-image: linear-gradient(to right, #FDFDFD,#F1F1F1 );
  padding: 15px;
  color: #E8721E;
  font-weight: bold;
}

.tipair b {
  color: #00325A;
}

.tipair img {
  width: 20px;
}

.tipairDivInd {
  background-image: linear-gradient(to right, #4076A1,#00325A );
  width: 100%;
  border-bottom: 10px solid #FF9E59;
  display: flex;
  justify-content: space-around;
}

.tipairind {
  width: 100%;
}

.tipairDivInd h5 {
  font-weight: bold;
  padding: 10px;
  margin-bottom: 20px;
}

.tipairDivInd > div:nth-child(1) {
  color: white;
}

.tipairDivInd > div:nth-child(3) {
  color: white;
}

.tipairDivInd > div:nth-child(2) {
  color: #00325A;
}

.ti1 {
  color: white !important;
}

.ti2 {
  color: #00325A !important;
}

.tipairind1 {
  background-image: linear-gradient(to right, #FFD45C, #D49400);
}

.tipairind2 {
  background-image: linear-gradient(to right, #F5F5F5, #D2D2D2);
}

.tipairind3 {
  background-image: linear-gradient(to right, #AD6718, #4A2400);
}

.tiindices {
  background-image: linear-gradient(to right, #4076A1, #00325A);
  padding: 50px 0 30px 0;
}

.tiindices > div {
  display: flex;
  justify-content: space-between;
  color: white;
}

.tiinline {
  width: 200px;
  /* border: 1px solid white; */
  background-color: white;
  height: 1px;
  margin-top: 5px;
}

.tiinind {
  width: 200px;
}

.sdcarousel {
  height: 320px;
  background: transparent linear-gradient(284deg, #00325A 0%, #4076A1 100%) 0% 0% no-repeat padding-box;
}

.sdnext {
  margin-top: 20px;
  color: #FFA15E;
  display: flex;
  justify-content: space-between;
}

.adcarousel1 {
  color: white;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.adcarousel1 > div:nth-child(1) {
  width: 60%;
}

.adcarousel1 > div:nth-child(2) {
  border: 1px solid grey;
  width: 250px;
  height: 150px;
  color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adcarousel2 {
  display: flex;
  justify-content: space-around;
  padding-top: 50px;
}

.adcarousel2 div {
  padding: 0 30px 0 30px;
  width: 20%;
  color: white;
  font-weight: bold;
  border-right: 1px solid whitesmoke;
}

.adcarousel2 h3 {
  font-weight: bold;
}

.adcarousel2 div:nth-last-child(1) {
  border-right: none;
  color: #f38e46;
}

.sdheader {
  background-image: linear-gradient(to right, #FF9E59, #C95400);
  padding: 10px 0 5px 0;
  text-align: center;
  color: white;
}

.sdheader > h3 {
  font-weight: bold;
}

.sdtable {
  margin-top: 50px;
}

.sdtablehead {
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(to right, #FDFDFD, #C7C7C7);
}

.sdtableheading {
  display: flex;
  width: 50%;
}

.sdtablehead > h4 {
  width: 50%;
  color: #00325A;
  font-weight: bold;
  padding: 2px;
}

.sdtableheading div {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  padding-top: 5px;
  background-image: linear-gradient(to right, #4076A1, #00325A);
  width: 33.33%;
  color: white;

}

.sdtableheading > div:nth-child(2) {
  background-image: linear-gradient(to right, #E8721E, #C45302);
}

.sdbody {
  background-image: linear-gradient(to right, #F1F1F1, #FDFDFD);
}

.sdbodydiv {
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.sdbodydiv > div {
  display: flex;
  justify-content: space-around;
  width: 50%;
}

.sdbodydiv > div > div:nth-child(1) {
  background-image: linear-gradient(to right, #00325A, #4076A1);
  color: white;
  font-weight: bold;
  padding: 8px 30px 0 30px;
  width: 30%;
  text-align: center;
}

.sdbodydiv > div > div:nth-child(2) {
  padding: 4px;
  color: #00325A;
  font-weight: bold;
  max-width: 50%;
  width: 70%;
  text-align: center;
}

.sdvalues {
  width: 50%;
}

.sdvalues > p {
  text-align: center;
  width: 33.33%;
  margin-top: 4px;
  font-weight: bold;
  color: #00325A;
}

.sdvalues > p:nth-child(2) {
  color: #E8721E;
}